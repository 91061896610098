import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'POST',
      formData: {},
      searchFormData: {},
    };
  },
  async mounted() {
    const createStartTime = this.formatTime(new Date().getTime(), 'Y-M-D 00:00:00');
    const createEndTime = this.formatTime(new Date().getTime(), 'Y-M-D 23:59:59');
    this.searchFormData = {
      ...this.searchFormData,
      createStartTime,
      createEndTime,
    };
    await this.getConfigList('page_list');
    this.formData = {
      ...this.formData,
      createStartTime,
      createEndTime,
    };
  },
  methods: {
    formatTime(timestamp, format) {
      const formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      let returnArr = [];
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      returnArr.push(year, month, day, hour, minute, second);
      returnArr = returnArr.map((a) => {
        let n = a;
        n = n.toString();
        return n[1] ? n : `0${n}`;
      });
      let aaa = format;
      for (const i in returnArr) {
        if (i) {
          aaa = aaa.replace(formatArr[i], returnArr[i]);
        }
      }
      return aaa;
    },
  },
};
